import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { DeleteEndUserModalWindow, ConfirmDeleteUserWindow } from '../../modals';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';

import { columnsEndUser } from './columns';
import oemColumns from '../OEM/columns';
import { CompanyInfoSub, UserInfoSub } from '../../components/ReactTable/renderFunctions';
import PropMgmtInfo from './components/PropMgmtInfo';
import DataList, { DataListPagination, DataListFilterV2 } from '../DataList';
import {
  openModalWindow,
  modalWindowSend,
  ADD_END_USERS_TO_PROP_MGMT_MODAL_ID,
  REMOVE_DATA_MODAL_ID
} from '../ModalWindow/slice';
import i18n from '../../i18n';
import { dataListReloadData } from '../DataList/slice';
import { AddEndUserToSupportWindow } from './modals';

import { SearchField, TopBar, Pagination } from '../../components';
import { dataListIDEndUsers, dataListIDOem } from './constants';
import { getSignInUserOemVisibleWebSelector } from '../../redux-store/selectors/signIn';
import { getDataListCurrentPropMgmtSelector } from '../../redux-store/selectors/dataList';
import {
  USERS_SOLAR_MANAGERS_USERS_URL,
  USERS_GET_PROP_MGMT_END_USERS_URL
} from '../../api/apiUrls';
import { ReactTableV2 } from '../../components/ReactTableV2';
import './index.scss';

const transformResponse = (data) => ({ total: data.total, data: data.list });
const transformResponseOem = (data) => (data.propertyManagementOemUser ? ({ total: 1, data: [data.propertyManagementOemUser] }) : []);

const PropMgmtDetails = ({
  openModalWindow: openModal,
  modalWindowSend: modalSend,
  visibleWeb,
  propMgmtDataList,
  match,
  myRoleType
}) => {
  const columns = columnsEndUser(myRoleType);
  const columnsOem = oemColumns(myRoleType, { hideRemove: true });
  const { [dataListIDEndUsers]: pageSettings } = useSelector(getPagesSettingsSelector);
  const { currentPropMgmt } = useSelector((state) => state.dataList);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { propMgmtID } = match.params;
  const propMgmtDataListURL = `/users/property-management/${propMgmtID}`;
  const connectedEndUsersUrl = `${USERS_GET_PROP_MGMT_END_USERS_URL}?propertyManagementUserId=${propMgmtID}`;
  const propMgmtHasLoaded = !!propMgmtDataList.data?._id;

  const sendAddEndUsers = (fields) => {
    const filteredData = {
      ...fields,
      pv_installer: fields.pv_installer === 'null' ? undefined : fields.pv_installer
    };

    modalSend(
      {
        modalID: ADD_END_USERS_TO_PROP_MGMT_MODAL_ID,
        requestConfig: {
          method: 'post',
          url: USERS_SOLAR_MANAGERS_USERS_URL,
          data: {
            ...filteredData,
            propertyManagementEmail: currentPropMgmt?.data?.email
          }
        },
        cb: () => dataListReloadData({ listID: dataListIDEndUsers })
      });
  };

  const sendRemoveUser = ({ id }) => modalSend({
    modalID: REMOVE_DATA_MODAL_ID,
    requestConfig: {
      method: 'delete',
      url: `/user/${id}`
    },
    cb: () => dataListReloadData({ listID: dataListIDEndUsers })
  });

  return (
    <>
      <TopBar visibleWeb={visibleWeb} showNavigation />
      <DataList
        listID="currentPropMgmt"
        listURL={propMgmtDataListURL}
        Component={PropMgmtInfo}
        params={{}}
      />
      {propMgmtHasLoaded && (
        <div className="container-fluid PropMgmtDetails">
          {activeTabIndex === 0 && (
            <div
              className="col-xl-4 col-sm-7 col-lg-4 col-md-5 col-10"
              style={{ margin: '30px 0 10px' }}
            >
              <DataListFilterV2
                Component={SearchField}
                componentProps={{
                  placeholder: i18n.t('searchAllTablesElements'),
                  defaultValue: pageSettings.queries.search,
                  searchByFields: 'sm_id'
                }}
                listURL={connectedEndUsersUrl}
                listID={dataListIDEndUsers}
                transformResponse={transformResponse}
              />
            </div>
          )}
          <Tabs selectedIndex={activeTabIndex} onSelect={setActiveTabIndex}>
            <TabList className="d-flex p-0">
              <Tab>
                <div className="row tabs-cont align-items-center">
                  <div className="col-auto mr-auto">
                    <div className="m-portlet__head-tools">
                      <div
                        className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x m-tabs-line--right nav-tab-links marginBottom6px"
                      >
                        <div className="nav-item m-tabs__item">
                          <div className="nav-link m-tabs__link">{i18n.t('SMUs')}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              {myRoleType !== 'oem' && (
                <Tab>
                  <div className="row tabs-cont align-items-center">
                    <div className="col-auto mr-auto">
                      <div className="m-portlet__head-tools">
                        <div
                          className="nav nav-tabs m-tabs-line m-tabs-line--danger m-tabs-line--2x m-tabs-line--right nav-tab-links marginBottom6px"
                        >
                          <div className="nav-item m-tabs__item">
                            <div className="nav-link m-tabs__link">{i18n.t('oem')}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              )}
              {activeTabIndex === 0 && (
                <li className="d-flex align-items-center ml-auto">
                  <a
                    className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp"
                    onClick={openModal.bind(null, { modalID: ADD_END_USERS_TO_PROP_MGMT_MODAL_ID, data: null })}
                  >
                    <span>
                      <span>{i18n.t(activeTabIndex === 0 ? 'addSMUbtn' : 'addPropMgmt')}</span>
                    </span>
                  </a>
                </li>
              )}
            </TabList>

            <TabPanel>
              <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
                <AddEndUserToSupportWindow onSubmit={sendAddEndUsers} propertyManagementUserId={propMgmtID} />
                <DeleteEndUserModalWindow onSubmit={sendRemoveUser} />
                <ConfirmDeleteUserWindow userRole={i18n.t('eUser').toLowerCase()} />

                <ReactTableV2
                  listID={dataListIDEndUsers}
                  listURL={connectedEndUsersUrl}
                  params={pageSettings.queries}
                  transformResponse={transformResponse}
                  columns={columns}
                  SubComponent={UserInfoSub}
                />
              </div>
              <DataListPagination
                Component={Pagination}
                listID={dataListIDEndUsers}
                defaultPageSize={10}
              />
            </TabPanel>
            {myRoleType !== 'oem' && (
              <TabPanel>
                <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
                  <ReactTableV2
                    listID={dataListIDOem}
                    listURL={propMgmtDataListURL}
                    params={pageSettings.queries}
                    transformResponse={transformResponseOem}
                    columns={columnsOem}
                    SubComponent={CompanyInfoSub}
                  />
                </div>
              </TabPanel>
            )}
          </Tabs>
        </div>
      )}
    </>
  );
};

PropMgmtDetails.propTypes = {
  openModalWindow: PropTypes.func.isRequired,
  modalWindowSend: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  propMgmtDataList: PropTypes.instanceOf(Object).isRequired,
  myRoleType: PropTypes.string.isRequired,
  visibleWeb: PropTypes.bool
};

const mapStateToProps = createStructuredSelector({
  propMgmtDataList: getDataListCurrentPropMgmtSelector,
  visibleWeb: getSignInUserOemVisibleWebSelector
});

export default connect(mapStateToProps, {
  openModalWindow,
  modalWindowSend
})(PropMgmtDetails);
